import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

import BlogItems from '../../util/blog'

const BlogPostCard = ({ post }) => (
  <Link href={post.frontmatter.slug} style={{ color: 'inherit', textDecoration: 'inherit'}}>
    <Card>
      {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
      <Card.Body>
        <Card.Title>{post.frontmatter.title}</Card.Title>
        <Card.Text>
          {(new Date(post.frontmatter.date)).toLocaleDateString(undefined, {year: 'numeric', month: 'long'})}
        </Card.Text>
        <Card.Text>
          {post.frontmatter.description}
        </Card.Text>
      </Card.Body>
    </Card>
  </Link>
)

const getKeys = (posts, sort = undefined) => {
  if (!sort) {
    return Object.keys(posts)
  }

  if (sort === 'asc') {
    return Object.keys(posts)
      .sort()
  }

  if (sort === 'desc') {
    return Object.keys(posts)
      .sort()
      .reverse()
  }
}

const PostsBySpecificKey = ({ posts, sort, defaultActiveKey }) => (
    <Accordion defaultActiveKey={defaultActiveKey}>
      {getKeys(posts, sort).map((groupName, key) => {
        return (
          <Accordion.Item eventKey={key} key={key}>
            <Accordion.Header>{groupName}</Accordion.Header>
            <Accordion.Body>
              {posts[groupName].map((post) => (
                <BlogPostCard post={post} />
              ))}
            </Accordion.Body>
            {/* <AccordianWithPosts
                    key={key}
                    name={groupName}
                    posts={posts[groupName]}
                    /> */}
          </Accordion.Item>
        )
      })}
    </Accordion>
  )


// const AccordianWithPosts = ({ posts, name }) => (
//   <AccordionItem>
//     <h2>
//       <AccordionButton>
//         <Box flex='1' textAlign='left'>
//           {name}
//         </Box>
//         <AccordionIcon />
//       </AccordionButton>
//     </h2>
//     <AccordionPanel>
//       <PostElements posts={posts} />
//     </AccordionPanel>
//   </AccordionItem>
// )

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  const blogItems = BlogItems(posts)
  if (posts.length === 0) {
    return (<div></div>)
  }


  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="All posts" />
      
      <Container>
        <h1>Blog</h1>

        <Tabs
          defaultActiveKey="date"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="date" title="By Date">
            <PostsBySpecificKey
              posts={blogItems.blogsByYear}
              sort={'desc'}
              defaultActiveKey={blogItems.years.map((val, key) => key)}
            />
          </Tab>
          <Tab eventKey="Category" title="By Category">
            <PostsBySpecificKey sort={'desc'} posts={blogItems.blogsByCategory}/>
          </Tab>
          <Tab eventKey="Tag" sort={'desc'} title="By Tag">
            <PostsBySpecificKey posts={blogItems.blogsByTag}/>
          </Tab>
        </Tabs>
      </Container>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark {
      nodes {
        excerpt
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          listable
          tags
          featuredImage
          category
          slug
        }
      }
    }
  }
`
