const preprocess = (posts) =>
  posts.filter((post) => !!post.frontmatter.listable)

const getBlogCategories = (posts) =>
  posts.reduce((result, post) => {
    const category = post.frontmatter.category

    !result.includes(category) && result.push(category)

    return result
  }, [])

const getBlogTags = (posts) =>
  posts.reduce((result, post) => {
    post.frontmatter.tags.forEach((tag) => {
      !result.includes(tag) && result.push(tag)
    })
    return result
  }, [])

const getBlogYears = (posts) =>
  posts.reduce((result, post) => {
    const date = new Date(post.frontmatter.date)

    !result.includes(date.getFullYear()) && result.push(date.getFullYear())

    return result
  }, [])


const getBlogsByTag = (posts, tags) => 
  tags.reduce((result, tag) => ({
    ...result,
    [tag]: posts.filter((post) => post.frontmatter.tags.includes(tag))
  }), {})

const getBlogsByCategory = (posts, category) => 
  category.reduce((result, category) => ({
    ...result,
    [category]: posts.filter((post) => post.frontmatter.category === category)
  }), {})

const getBlogsByYear = (posts, years) => 
years.reduce((result, year) => ({
    ...result,
    [year]: posts.filter((post) => (new Date(post.frontmatter.date)).getFullYear() === year)
  }), {})

export default (posts) => {
  const preprocessedPosts = preprocess(posts)

  const categories = getBlogCategories(preprocessedPosts)
  const tags = getBlogTags(preprocessedPosts)
  const years = getBlogYears(preprocessedPosts)
  
  return {
    categories,
    tags,
    years,
    blogPosts: preprocessedPosts,
    blogsByTag: getBlogsByTag(preprocessedPosts, tags),
    blogsByCategory: getBlogsByCategory(preprocessedPosts, categories),
    blogsByYear: getBlogsByYear(preprocessedPosts, years)
  }
}